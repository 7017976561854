
@import './assets/styles/login';
@import './assets/styles/side-bar';
@import './assets/styles/error';
@import './assets/styles/card';
@import './assets/styles/nav';
@import './assets/styles/home';

.App {
  text-align: center;
}

.full-screen-height{
  min-height: 100vh
}
.middle-div {
  position : absolute;    
  width    : 200px;
  height   : 200px;
  left     : 50%;
  top      : 50%;
  margin-left : -100px; /* half of the width  */
  margin-top  : -100px; /* half of the height */
}



legend.form-label {
  font-size: 1.5rem;
  color: #3d658f;
}

.control-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: #52657a;
}

.form-hint {
  font-size: 0.6rem;
  line-height: 1.4;
  margin: -5px auto 5px;
  color: #727272;
  text-align: left;
}
.form-hint.error {
  color: #c00;
  font-size: 0.8rem;
}

button.btn {
  letter-spacing: 1px;
  font-size: 1rem;
  // font-weight: 600;
}

.password-count {
  bottom: 16px;
  right: 10px;
  font-size: 1rem;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}
.strength-meter:before,
.strength-meter:after {
  content: "";
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}
.strength-meter:before {
  left: calc(20% - 3px);
}
.strength-meter:after {
  right: calc(20% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.strength-meter-fill[data-strength="0"] {
  width: 20%;
  background: darkred;
}
.strength-meter-fill[data-strength="1"] {
  width: 40%;
  background: orangered;
}
.strength-meter-fill[data-strength="2"] {
  width: 60%;
  background: orange;
}
.strength-meter-fill[data-strength="3"] {
  width: 80%;
  background: yellowgreen;
}
.strength-meter-fill[data-strength="4"] {
  width: 100%;
  background: green;
}
