.header-logo-img{
    width: 105px;
    max-height: 55px;
    left: 30px;
    position: absolute;
}
.navigation{
    position: absolute;
    margin-top: 7px;
    width: 100%;
    background: white;
    height: 100px;
}
.under-image{
    position: absolute;
    right: 0;
    height: 100%;
    margin-top: -1%;
    bottom: 0;
}