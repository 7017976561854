.login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    padding: 40px;
    background: rgba(255, 255, 255, 0.92);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .5);
    border-radius: 10px;
}

.forget-p {
    font-weight: normal;
    text-decoration: none !important;
}

.fs-18 {
    font-size: 18px;
}

.reset-success {
    font-size: 20px;

    p {
        font-size: 14px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-size: auto;
    background: url(../img/login-bg.png) round;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}
.resend-login{
    position: absolute;
    right: 52px;
    top: 198px;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    color: blueviolet !important;
    font-weight: 500;
}

.sign-text {
    color: #495057;
}

h6 {
    font-size: 0.75rem;
}

.or-text {
    h2 {
        display: flex;
        flex-direction: row;
        font-size: 0.75rem;
    }

    h2:before,
    h2:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #afb5bb;
        margin: auto 10px;
    }

}

.btn-radius {
    border-radius: 2.5rem !important;
}

.btn-lg{
    height: 45px;
}
.custom-btn-color-1 {
    color: #ffffff;
    background-color: #402B65 ;
    border: 1px solid #402B65;
}

.custom-btn-color-1:hover {
    background-color: #270661;
}

.custom-btn-color-2 {
    color: #402B65;
    background-color: #402b651a;
    background-image: none;
    border-color: #402B65;
}

.custom-btn-color-2:hover {
    color: #ffffff;
    background-color: #270661;
    border-color: #270661;
}

.form-signin{
    input[type=email], input[type=password],input[type=text],input[type=number]{
        border-radius: 2.5rem !important;
        font-size: 1rem;
        height: 2.8rem;
        border: 1px solid #AC9CCB;
    }

    ::placeholder {
        color: #AC9CCB;
    }
}

.login-error-message {
    width: 90%;
    padding: 3px;
    height: 28px;
    margin-left: 5%;
    margin-top: 2%;
    margin-right: 5%;
}

.custom-control-label::before {
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #AC9CCB;
}

.custom-control-label::after {
    width: 1.2rem;
    height: 1.2rem;

}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #6d529c;
    border: 1px solid #402B65;
}

.custom-control-label,
.forget-text a {
    color: #6d529c !important;
}

//dashboard styles

.singout {
    top: 15px;
    position: absolute;
    right: 0;
}

.sb-topnav {
    height: 56px;
    z-index: 10000 !important;
}

.bg-white {
    background-color: #fff !important;
}

.shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}

.navbar-brand {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}

.border-none {
    border: none !important;
}

.border-top {
    border-top: 1px solid #f1f1f1 !important;
}

.user-role {
    font-size: 14px;
    color: #1d1d1d !important;
}

.sidebar-dropdown span {
    color: #3a3a3a !important;
}

.sidebar-dropdown span:hover {
    color: #0061F2 !important;
}

//.header-area{
//    padding-left: 1.5rem;
//    padding-right: 1.5rem;
//    padding-bottom: 6rem !important;
//    background-image: linear-gradient(135deg, #0061f2 0%, rgba(105, 0, 199, 0.8) 100%);
//}
.dev-div {
    background-image: linear-gradient(135deg, #0061f2 0%, rgba(105, 0, 199, 0.8) 100%);
}

.showHidePassword {
  position: absolute;
  top: 34%;
  right: 6%;
  color: #aa97ce;
  cursor: pointer;
  font-size: 1rem;
}
.showNewPassoword{
    position: absolute;
    top: 245px;
    right: 13%;
    color: #aa97ce;
    cursor: pointer;
    font-size: 1rem;
}
.forgetNewPasswordEye{
    position: absolute;
    top: 338px;
    right: 13%;
    color: #aa97ce;
    cursor: pointer;
    font-size: 1rem;
}

.showHidePassword:hover {
  color: #6d529c;
}
