.card{
    width: 200px;
    width: 200px;
    border-radius: 20px;
    opacity: 1;
}
.card-img-top {
    width: 70%;
    margin-top: 10px;
    align-self: center;
}
.btn-to{
    width: 100px;
}